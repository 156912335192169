import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';

import Player from "components/Player";

import getAvatarById from "constants/avatars";

import logo from "images/Host/Logo.png";
import background from "images/Host/BG.png";
import mutedIcon from "images/Host/scrawl_muted.png";
import unmutedIcon from "images/Host/scrawl_unmuted.png";
import fullscreenIcon from "images/Host/scrawl_fullscreen.png";
import helpIcon from "images/Host/scrawl_help.png";
import folderFront from "images/Host/Folder/Folder_Front.png";
import folderBack from "images/Host/Folder/Folder_Back.png";
import Round1 from "images/Host/RoundNumbers/Folder_Round 1.png";
import Round2 from "images/Host/RoundNumbers/Folder_Round 2.png";
import Round3 from "images/Host/RoundNumbers/Folder_Round 3.png";
import Round4 from "images/Host/RoundNumbers/Folder_Round 4.png";
import Round5 from "images/Host/RoundNumbers/Folder_Round 5.png";
import Round6 from "images/Host/RoundNumbers/Folder_Round 6.png";
import Round7 from "images/Host/RoundNumbers/Folder_Round 7.png";
import Round8 from "images/Host/RoundNumbers/Folder_Round 8.png";
import folderCardWhite from "images/Host/Folder/Folder_Card.png";
import folderCardGreen from "images/Host/Folder/Folder_Reveal Card.png";
import suspectBox from "images/Host/character info.png"
import wordCircle from "images/Host/word reveal.png";
import wrongStamp from "images/Host/Wrong_Stamp.png";
import correctStamp from "images/Host/Correct_stamp.png";
import votedStamp from "images/Host/Voted_stamp.png";
import postItNote from "images/Host/Post it.png";
import leaderboardBg from "images/Host/Scoreboard.png";
import rightPodium1 from "images/Host/Chameleon_Podium_Right_1.png";
import rightPodium2 from "images/Host/Chameleon_Podium_Right_2.png";
import rightPodium3 from "images/Host/Chameleon_Podium_Right_3.png";
import leftPodium2 from "images/Host/Chameleon_Podium_Left_2.png";
import leftPodium3 from "images/Host/Chameleon_Podium_Left_3.png";
import middlePodium from "images/Host/Chameleon_Podium_Middle.png";
import ButtonImg1 from "images/Host/Button BG_1.png";
import ButtonImg2 from "images/Host/Button BG_2.png";

import ChameleonCaughtSFX from "audio/Chameleon Caught.wav";
import ChameleonGotAwaySFX from "audio/Chameleon got Away.wav";
import GameEndSFX from "audio/Game End.wav";
import BGMusic from "audio/main-music-short.mp3";
import NewRoundSFX from "audio/New Round.wav";
import PaperSlide1SFX from "audio/Paper slide 1.wav";
import PaperSlide2SFX from "audio/Paper slide 2.wav";
import PaperSlide3SFX from "audio/Paper slide 3.wav";
import PlayerActionSFX from "audio/Player action (submitted word_voted).wav";
import Timer5SecondsClockSFX from "audio/timer-5-seconds-clock.mp3";
import DrumRollSFX from "audio/drum-roll.wav";

import ChameleonRevealAnim from "animations/tutorial/Chameleon Reveal.js";
import PointyFingerAnim from "animations/tutorial/Pointy Finger.js";
import BubbleCaptainAnim from "animations/tutorial/Speech Bubble_Captain.js";
import BubbleIcebergAnim from "animations/tutorial/Speech Bubble_Iceberg.js";
import BubbleMonsterAnim from "animations/tutorial/Speech Bubble_Monster.js";
import BubbleTitanicAnim from "animations/tutorial/Speech Bubble_Titanic.js";
import BubbleWaterAnim from "animations/tutorial/Speech Bubble_Water.js";

import "animate.css";
import styles from 'components/TutorialStyles.module.scss';


var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    ChameleonCaught: {
        import: ChameleonCaughtSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    ChameleonGotAway: {
        import: ChameleonGotAwaySFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    GameEnd: {
        import: GameEndSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NewRound: {
        import: NewRoundSFX,
        loaded: null,
        volume: 0.8,
        loop: false,
    },
    PaperSlide1: {
        import: PaperSlide1SFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    PaperSlide2: {
        import: PaperSlide2SFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    PaperSlide3: {
        import: PaperSlide3SFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    PlayerAction: {
        import: PlayerActionSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Timer5SecondsClock: {
        import: Timer5SecondsClockSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DrumRoll: {
        import: DrumRollSFX,
        loaded: null,
        volume: 1,
        loop: false,
    }
};


export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            showStepOne: true,
            showStepOneTextOne: false,
            showStepOneTextTwo: false,
            stepOnePlayers: [
                {
                    avatar: 2,
                    show: false,
                },
                {
                    avatar: 5,
                    show: false,
                },
                {
                    avatar: 8,
                    show: false,
                    showChameleon: false,
                },
                {
                    avatar: 9,
                    show: false,
                }
            ],

            showStepTwo: true,
            showStepTwoText: false,
            stepTwoText: "Each round, a \"Secret Word\" is \nrandomly selected on a Topic Card",
            stepTwoPlayers: [
                {
                    avatar: 2,
                    show: false,
                    showAnswer: false,
                    answerAnim: BubbleIcebergAnim,
                    showVote: false,
                    voteStyle: styles.one,
                },
                {
                    avatar: 5,
                    show: false,
                    showAnswer: false,
                    answerAnim: BubbleCaptainAnim,
                    showVote: false,
                    voteStyle: styles.two,
                },
                {
                    avatar: 8,
                    show: false,
                    showAnswer: false,
                    showChameleon: false,
                    answerAnim: BubbleMonsterAnim,
                    showVote: false,
                    voteStyle: styles.one,
                },
                {
                    avatar: 9,
                    show: false,
                    showAnswer: false,
                    answerAnim: BubbleWaterAnim,
                    showVote: false,
                    rotation: -90,
                    voteStyle: styles.three,
                }
            ],
            showStepTwoFolder: false,
            stepTwoCard: {
                "Topic": "Movies",
                "Answers": [
                    "Jurassic Park",
                    "Jaws",
                    "Raiders of the Lost Ark",
                    "The Avengers",
                    "Transformers",
                    "Toy Story",
                    "Home Alone",
                    "Titanic",
                    "E.T.",
                    "The Wizard of Oz",
                    "King Kong",
                    "The Matrix",
                    "Shrek",
                    "The Godfather",
                    "Finding Nemo",
                    "Avatar"
                ]
            },
            showStepTwoSecretWord: false,
            moveStepTwoPlayers: false,

            showStepThree: true,
            showStepThreeText: false,
            showStepThreeFolder: false,
            showStepThreeChameleon: false,
            showStepThreeGuess: false,

            showStepFour: true,
            showStepFourText: false,
        };
        this.snatcherAnimRef = React.createRef();

    }

    componentDidMount() {
        this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    doStepOne() {
        this.setState({ showStepOne: true, showStepOneTextOne: true, });
        setTimeout(() => {
            let playerTime = 0;
            let stepOnePlayers = [...this.state.stepOnePlayers];
            stepOnePlayers.forEach((x, i) => {
                setTimeout(() => {
                    stepOnePlayers[i].show = true;
                    this.setState({ stepOnePlayers, });
                    playerTime += (i * 200);
                }, (i * 200));
            });

            setTimeout(() => {
                this.setState({ showStepOneTextTwo: true });

                setTimeout(() => {
                    stepOnePlayers = [...this.state.stepOnePlayers];
                    stepOnePlayers[2].showChameleon = true;
                    this.setState({ stepOnePlayers });
                    setTimeout(() => {
                        this.setState({ showStepOne: false, });
                        setTimeout(() => {
                            this.doStepTwo();
                        }, 1000);
                    }, 3000);
                }, 2500);
            }, (2000 + playerTime));
        }, 1000);
    }

    doStepTwo() {
        this.setState({ showStepTwo: true, });
        setTimeout(() => {
            let playerTime = 0;
            let stepTwoPlayers = [...this.state.stepTwoPlayers];
            stepTwoPlayers.forEach((x, i) => {
                setTimeout(() => {
                    stepTwoPlayers[i].show = true;
                    this.setState({ stepTwoPlayers, });
                    playerTime += (i * 200);
                }, (i * 200));
            });
            setTimeout(() => {
                this.setState({ showStepTwoText: true, showStepTwoFolder: true, });

                setTimeout(() => {
                    this.setState({ showStepTwoSecretWord: true, });

                    setTimeout(() => {
                        this.changeStepTwoText("One by one, players each give a clue to \nprove they know what the Secret Word is.");

                        setTimeout(() => {
                            playerTime = 0;
                            stepTwoPlayers = [...this.state.stepTwoPlayers];
                            stepTwoPlayers.forEach((x, i) => {
                                setTimeout(() => {
                                    if (i != 2) {
                                        stepTwoPlayers[i].showAnswer = true;
                                        this.setState({ stepTwoPlayers, });
                                        playerTime += (i * 500);
                                    }
                                }, (i * 500));
                            });

                            setTimeout(() => {
                                this.changeStepTwoText("The Chameleon must try to blend in \nand make up a clue as well...");

                                setTimeout(() => {
                                    stepTwoPlayers = [...this.state.stepTwoPlayers];
                                    stepTwoPlayers[2].showAnswer = true;
                                    this.setState({ stepTwoPlayers });

                                    setTimeout(() => {
                                        this.changeStepTwoText("Finally, everyone must vote.\nWho do they think the Chameleon was?");
                                        this.setState({ showStepTwoFolder: false, });
                                        setTimeout(() => {
                                            this.setState({ moveStepTwoPlayers: true, });

                                            setTimeout(() => {
                                                playerTime = 0;
                                                stepTwoPlayers = [...this.state.stepTwoPlayers];
                                                stepTwoPlayers.forEach((x, i) => {
                                                    setTimeout(() => {
                                                        if (i != 2) {
                                                            stepTwoPlayers[i].showVote = true;
                                                            this.setState({ stepTwoPlayers, });
                                                            playerTime += (i * 500);
                                                        }
                                                    }, (i * 500));
                                                });
                                                setTimeout(() => {
                                                    stepTwoPlayers = [...this.state.stepTwoPlayers];
                                                    stepTwoPlayers[2].showChameleon = true;
                                                    this.setState({ stepTwoPlayers });

                                                    setTimeout(() => {
                                                        this.setState({ showStepTwo: false });
                                                        this.doStepThree();
                                                    }, 4000);
                                                }, (3000 + playerTime));
                                            }, 2000);
                                        }, 500);
                                    }, 2000);
                                }, 2000)
                            }, (2000 + playerTime));
                        }, 2000);
                    }, 2000);
                }, 3000);


            }, (1000 + playerTime));
        }, 1000);
    }

    changeStepTwoText(newText) {
        this.setState({ showStepTwoText: false });
        setTimeout(() => {
            this.setState({ stepTwoText: newText, showStepTwoText: true, });
        }, 750);
    }

    doStepThree() {
        this.setState({ showStepThree: true, });
        setTimeout(() => {
            this.setState({ showStepThreeFolder: true, showStepThreeChameleon: true, });
            setTimeout(() => {
                this.setState({ showStepThreeText: true, });

                setTimeout(() => {
                    this.setState({ showStepThreeGuess: true });

                    setTimeout(() => {
                        this.setState({ showStepThree: false, });
                        this.doStepFour();
                    }, 4000);
                }, 3000);
            }, 3000);

        }, 1000);
    }

    doStepFour() {
        this.setState({ showStepFour: true, });
        setTimeout(() => {
            this.setState({ showStepFourText: true, });

            setTimeout(() => {
                this.setState({ showStepFour: false, });
                this.props.room.send("end_tutorial", {});
            }, 4000);
        }, 1000);
    }

    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}>
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepOneTextOne && styles.show}`}>Everybody act natural...</div>
                    <div className={styles.playerRow}>
                        {
                            this.state.stepOnePlayers.map((x) => {
                                return <div className={`${styles.player} ${x.show && styles.show}`}>
                                    <Lottie
                                        options={getAvatarById(x.avatar).idleAnim}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                    />
                                    {
                                        x.showChameleon &&
                                        <div className={styles.chameleon}>
                                            <Lottie
                                                options={ChameleonRevealAnim}
                                                width="100%"
                                                height="100%"
                                                isClickToPauseDisabled={true}
                                            />
                                        </div>
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepOneTextTwo && styles.show}`}>...It's time to play 'The Chameleon'</div>
                </div>
                <div className={`${styles.stepTwo} ${styles.step} ${this.state.showStepTwo && styles.show}`} style={{ width: "100%" }}>
                    <div className={`${styles.text} ${this.state.showStepTwoText && styles.show}`} style={{ marginBottom: 0, }}>{this.state.stepTwoText}</div>
                    <div className={`${styles.playerCol} ${styles.left} ${this.state.moveStepTwoPlayers && styles.move}`}>
                        {
                            this.state.stepTwoPlayers.map((x, i) => {
                                if (i < 2) {
                                    return <div className={`${styles.player} ${styles.left} ${x.show && styles.show}`}>
                                        <Lottie
                                            options={getAvatarById(x.avatar).idleAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                        />
                                        {
                                            x.showAnswer &&
                                            <div className={styles.answer}>
                                                <Lottie
                                                    options={x.answerAnim}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                        }
                                        {
                                            x.showVote &&
                                            <div className={`${styles.vote} ${x.voteStyle}`}>
                                                <Lottie
                                                    options={PointyFingerAnim}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            })
                        }
                    </div>
                    <div className={`${styles.playerCol} ${styles.right} ${this.state.moveStepTwoPlayers && styles.move}`}>
                        {
                            this.state.stepTwoPlayers.map((x, i) => {
                                if (i > 1) {
                                    return <div className={`${styles.player} ${styles.right} ${x.show && styles.show}`}>
                                        <Lottie
                                            options={getAvatarById(x.avatar).idleAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                        />
                                        {
                                            x.showChameleon &&
                                            <div className={styles.chameleon}>
                                                <Lottie
                                                    options={ChameleonRevealAnim}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                        }
                                        {
                                            x.showAnswer &&
                                            <div className={styles.answer}>
                                                <Lottie
                                                    options={x.answerAnim}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                        }
                                        {
                                            x.showVote &&
                                            <div className={`${styles.vote} ${x.voteStyle}`}>
                                                <Lottie
                                                    options={PointyFingerAnim}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            })
                        }
                    </div>
                    <div className={`${styles.roundFolder} ${this.state.showStepTwoFolder && styles.show}`}>
                        <div className={`${styles.folderSegment} ${styles.rotate1}`}>
                            <img src={folderCardWhite} className={`${styles.sheet}`} />
                            <div className={`${styles.cardContent} ${styles.white}`}>
                                <div className={styles.questionCard}>
                                    <div className={styles.questionTitle}>{this.state.stepTwoCard.Topic}</div>
                                    <div className={styles.questionTable}>
                                        {
                                            this.state.stepTwoCard.Answers?.map((x, index) => {
                                                return <div className={styles.tableItem}>
                                                    <div className={styles.itemText}>{x}</div>
                                                    {
                                                        x == "Titanic" && this.state.showStepTwoSecretWord &&
                                                        <img src={wordCircle} className={styles.wordCircle} />
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepThreeText && styles.show}`} style={{marginBottom: 0,}}>If you successfully find the<br />Chameleon, they get one guess<br />at the secret word!</div>
                    <div className={styles.rowBox}>
                        <div className={`${styles.roundFolder} ${this.state.showStepThreeFolder && styles.show}`}>
                            <div className={`${styles.folderSegment} ${styles.rotate1}`}>
                                <img src={folderCardWhite} className={`${styles.sheet}`} />
                                <div className={`${styles.cardContent} ${styles.white}`}>
                                    <div className={styles.questionCard}>
                                        <div className={styles.questionTitle}>{this.state.stepTwoCard.Topic}</div>
                                        <div className={styles.questionTable}>
                                            {
                                                this.state.stepTwoCard.Answers?.map((x, index) => {
                                                    return <div className={styles.tableItem}>
                                                        <div className={styles.itemText}>{x}</div>
                                                        {/*{*/}
                                                        {/*    x == "Titanic" && this.state.showStepTwoSecretWord &&*/}
                                                        {/*    <img src={wordCircle} className={styles.wordCircle} />*/}
                                                        {/*}*/}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.showStepThreeChameleon &&
                            <div className={styles.chameleon}>
                                <Lottie
                                    options={ChameleonRevealAnim}
                                    width="100%"
                                    height="100%"
                                    isClickToPauseDisabled={true}
                                />
                                {
                                    this.state.showStepThreeGuess &&
                                    <div className={styles.guess}>
                                        <Lottie
                                            options={BubbleTitanicAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`}>
                    <div className={`${styles.text} ${styles.larger} ${this.state.showStepFourText && styles.show}`}>GOOD LUCK!</div>
                </div>
            </div>
        );
    }
}