import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import Player from "components/Player";
import Menu from "components/Menu";
import Tutorial from "components/Tutorial";

import getAvatarById from "constants/avatars";

import timerTurning from "animations/timerTurning.js";
import timerEnd from "animations/timerEnd.js";

import logo from "images/Host/Logo.png";
import background from "images/Host/BG.png";
import mutedIcon from "images/Host/scrawl_muted.png";
import unmutedIcon from "images/Host/scrawl_unmuted.png";
import fullscreenIcon from "images/Host/scrawl_fullscreen.png";
import helpIcon from "images/Host/scrawl_help.png";
import folderFront from "images/Host/Folder/Folder_Front.png";
import folderBack from "images/Host/Folder/Folder_Back.png";
import Round1 from "images/Host/RoundNumbers/Folder_Round 1.png";
import Round2 from "images/Host/RoundNumbers/Folder_Round 2.png";
import Round3 from "images/Host/RoundNumbers/Folder_Round 3.png";
import Round4 from "images/Host/RoundNumbers/Folder_Round 4.png";
import Round5 from "images/Host/RoundNumbers/Folder_Round 5.png";
import Round6 from "images/Host/RoundNumbers/Folder_Round 6.png";
import Round7 from "images/Host/RoundNumbers/Folder_Round 7.png";
import Round8 from "images/Host/RoundNumbers/Folder_Round 8.png";
import folderCardWhite from "images/Host/Folder/Folder_Card.png";
import folderCardGreen from "images/Host/Folder/Folder_Reveal Card.png";
import suspectBox from "images/Host/character info.png"
import wordCircle from "images/Host/word reveal.png";
import wrongStamp from "images/Host/Wrong_Stamp.png";
import correctStamp from "images/Host/Correct_stamp.png";
import votedStamp from "images/Host/Voted_stamp.png";
import postItNote from "images/Host/Post it.png";
import leaderboardBg from "images/Host/Scoreboard.png";
import rightPodium1 from "images/Host/Chameleon_Podium_Right_1.png";
import rightPodium2 from "images/Host/Chameleon_Podium_Right_2.png";
import rightPodium3 from "images/Host/Chameleon_Podium_Right_3.png";
import leftPodium2 from "images/Host/Chameleon_Podium_Left_2.png";
import leftPodium3 from "images/Host/Chameleon_Podium_Left_3.png";
import middlePodium from "images/Host/Chameleon_Podium_Middle.png";
import ButtonImg1 from "images/Host/Button BG_1.png";
import ButtonImg2 from "images/Host/Button BG_2.png";

import ChameleonCaughtSFX from "audio/Chameleon Caught.wav";
import ChameleonGotAwaySFX from "audio/Chameleon got Away.wav";
import GameEndSFX from "audio/Game End.wav";
import BGMusic from "audio/main-music-short.mp3";
import NewRoundSFX from "audio/New Round.wav";
import PaperSlide1SFX from "audio/Paper slide 1.wav";
import PaperSlide2SFX from "audio/Paper slide 2.wav";
import PaperSlide3SFX from "audio/Paper slide 3.wav";
import PlayerActionSFX from "audio/Player action (submitted word_voted).wav";
import Timer5SecondsClockSFX from "audio/timer-5-seconds-clock.mp3";
import DrumRollSFX from "audio/drum-roll.wav";

import "animate.css";
import styles from 'components/HostStyles.module.scss';
import ErrorModal from './Utility/ErrorModal';


const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    ChameleonCaught: {
        import: ChameleonCaughtSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    ChameleonGotAway: {
        import: ChameleonGotAwaySFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    GameEnd: {
        import: GameEndSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NewRound: {
        import: NewRoundSFX,
        loaded: null,
        volume: 0.8,
        loop: false,
    },
    PaperSlide1: {
        import: PaperSlide1SFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    PaperSlide2: {
        import: PaperSlide2SFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    PaperSlide3: {
        import: PaperSlide3SFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    PlayerAction: {
        import: PlayerActionSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Timer5SecondsClock: {
        import: Timer5SecondsClockSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DrumRoll: {
        import: DrumRollSFX,
        loaded: null,
        volume: 1,
        loop: false,
    }
};


const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    TopicVote: "topic_vote",
    Answering: "answering",
    Discussing: "discussing",
    PlayerVote: "player_vote",
    DecidingVote: "deciding_vote",
    SecretGuess: "secret_guess",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "chameleon";


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            reconnectionToken: "",
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,
            logStreamId: "",

            players: [],
            //players: [
            //    {
            //        name: "BOB",
            //        avatar: 1,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 6,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 6,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 3,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 21,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 3,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 21,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 1,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 21,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 1,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 21,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 1,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 21,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 1,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 21,
            //        chameleonData: {
            //            currentGuess: "",
            //            score: 1,
            //        }
            //    }
            //],
            playersRequired: 3,
            showPlayers: true,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,

            skipTutorialCount: 0,
            timerOptions: timerTurning,
            timerText: 0,
            showTimer: false,

            showStartButtons: true,
            //showStartButtons: false,
            showPlayAgainButtons: false,
            //showPlayAgainButtons: true,

            roundNumberImage: Round1,

            showFolder: false,
            dropCover: false,
            dropQuestion: false,
            dropSuspects: false,
            dropGuessSheet: false,
            dropRevealSheet: false,
            dropWordGuessSheet: false,

            question: {},

            suspects: [],

            guessChameleon: {},
            revealChameleon: {},
            guessSubText: "",
            revealSubText: "",

            showGuessedWord: false,
            guessedWord: "",
            showStamp: false,
            showCorrectWord: false,
            secretWord: "",
            guessCorrect: false,

            postItText: "",
            showPostItNote: false,

            showWinnerSection: false,
            //showWinnerSection: true,
            winners: [],
            doConfetti: false,

            gotLocationPing: true,
            connectionIssue: false,

            showStartWarning: false,
        };
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

    }

    componentDidMount() {
        this.setTags();

        this.doReconnect();

        this.toggleMute(true, false);
        this.initAudio();
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.BgMusic, true, 0.15);
    }

    preloadAudio() {
        console.log("Preload audio");
        console.log("-------------");
        for (let key in audio) {
            console.log("Loading audio : ", key);
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        console.log("Playing audio  : ", audio);
        if (audio.loaded) audio.loaded.play();
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });


    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            setTimeout(() => { // animate in timeout
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };
                statePlayer.showPlayer = true;
                statePlayers[pos] = statePlayer;
                console.log("show player : " + player.name)
                this.setState({ players: statePlayers });
            }, 500);
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.state.players.length; i++) {
            let value = this.state.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartButtons: false, showStartWarning: false, });
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
        }
    }

    goToLobby = () => {
        this.state.room.send("change_game", {});
    }

    signalNewGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.state.players.length; i++) {
            let value = this.state.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showPlayAgainButtons: false, showWinnerSection: false, showStartWarning: false, });
            setTimeout(() => {
                this.state.room.send("chameleon_new_game", {});
            }, 1000);
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmNewGame = () => {
        this.signalNewGame(true);
    }

    confirmStartGame = () => {
        this.signalStartGame(true);
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    getArrayOfPlayers(players) {
        let arrPlayers = [];
        for (const [key, value] of Object.entries(players)) {
            arrPlayers.push(value);
        };
        return arrPlayers;
    }

    getVoteCount(id) {
        let count = "";
        this.state.players.forEach((x) => {
            if (x.chameleonData.votePlayerId == id) {
                count += "1";
            }
        });
        return count;
    }

    setRoundNumber(roundNumber) {
        let newImg = Round1;
        switch (roundNumber) {
            case 2:
                newImg = Round2;
                break;
            case 3:
                newImg = Round3;
                break;
            case 4:
                newImg = Round4;
                break;
            case 5:
                newImg = Round5;
                break;
            case 6:
                newImg = Round6;
                break;
            case 7:
                newImg = Round7;
                break;
            case 8:
                newImg = Round8;
                break;
        }
        this.setState({ roundNumberImage: newImg, });
    }

    finishWordGuess() {
        setTimeout(() => {
            this.setState({ showFolder: false, dropWordGuessSheet: true, });
            setTimeout(() => {
                this.state.room.send("end_round", {});
            }, 2000);
        }, 3000);
    }

    resetRoundVals() {
        this.setState({
            dropCover: false,
            dropQuestion: false,
            dropSuspects: false,
            dropGuessSheet: false,
            dropRevealSheet: false,
            dropWordGuessSheet: false,

            showGuessedWord: false,
            guessedWord: "",
            showStamp: false,
            showCorrectWord: false,
            secretWord: "",
            guessCorrect: false,
        })
    }

    doPostItNote = (postItText, callback = null) => {
        this.setState({ postItText, });
        setTimeout(() => {
            this.playAudio(audio.PaperSlide2);
            this.setState({ showPostItNote: true, });

            setTimeout(() => {
                this.playAudio(audio.PaperSlide3);
                this.setState({ showPostItNote: false, });

                setTimeout(() => {
                    if (callback) callback();
                }, 1500);
            }, 4500);
        }, 100);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    getSortedPlayersByScore() {
        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.chameleonData.score - a.chameleonData.score;
        });
        let lastIndex = 1;
        return <React.Fragment>
            {
                statePlayers.map((x, index, arr) => {
                    if (index > 0 && x.chameleonData.score < arr[index - 1].chameleonData.score) lastIndex++;
                    return <tr className={`${styles.tableRow} ${styles.player}`}>
                        <img src={leaderboardBg} className={styles.bgImg} />
                        <div className={styles.position}>{lastIndex}</div>
                        <div className={styles.name}>{x.name}</div>
                        <div className={styles.score}>{x.chameleonData.score}<span className={styles.smaller}>pts</span></div>
                        <div className={styles.potato}>
                            <Lottie
                                options={getAvatarById(x.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </tr>
                })
            }
        </React.Fragment>
    }

    getWinnerBoxes() {
        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.chameleonData.score - a.chameleonData.score;
        });

        let playerOne = statePlayers[0];
        let playerTwo = statePlayers[1];
        let playerThree = statePlayers[2];

        if (playerOne && playerTwo && playerThree) {

            return <React.Fragment>
                <div className={`${styles.podium} ${styles.right}`}>
                    <img className={styles.boxes} src={playerThree.chameleonData.score == playerTwo.chameleonData.score ? playerThree.chameleonData.score == playerOne.chameleonData.score ? rightPodium3 : rightPodium2 : rightPodium1} />
                    <div className={`${styles.potato} ${playerThree.chameleonData.score == playerTwo.chameleonData.score ? playerThree.chameleonData.score == playerOne.chameleonData.score ? styles.pos1 : styles.pos2 : styles.pos3}`}>
                        <Lottie
                            options={getAvatarById(playerThree.avatar).idleAnim}
                            width="100%"
                            height="100%"
                            isClickToPauseDisabled={true}
                        />
                    </div>
                </div>
                <div className={`${styles.podium} ${styles.left}`}>
                    <img className={styles.boxes} src={playerTwo.chameleonData.score == playerOne.chameleonData.score ? leftPodium3 : leftPodium2} />
                    <div className={`${styles.potato} ${playerTwo.chameleonData.score == playerOne.chameleonData.score ? styles.pos1 : styles.pos2}`}>
                        <Lottie
                            options={getAvatarById(playerTwo.avatar).idleAnim}
                            width="100%"
                            height="100%"
                            isClickToPauseDisabled={true}
                        />
                    </div>
                </div>
                <div className={`${styles.podium} ${styles.middle}`}>
                    <img className={styles.boxes} src={middlePodium} />
                    <div className={`${styles.potato} ${styles.pos1}`}>
                        <Lottie
                            options={getAvatarById(playerOne.avatar).idleAnim}
                            width="100%"
                            height="100%"
                            isClickToPauseDisabled={true}
                        />
                    </div>
                </div>
            </React.Fragment>
        } else {
            return null;
        }
    }

    hideTimer() {
        this.setState({ showTimer: false, });

        if (audio.Timer5SecondsClock.loaded) audio.Timer5SecondsClock.loaded.stop();
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                LoggingService.streamLog(this.state.logStreamId, "Host Connection Issue Detected at Chameleon");
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const roomId = this.getQueryStringValue("roomId");
            const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");
            console.log(roomId);
            console.log(token);

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken});
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });

                        this.setState({ roomState: state, maxVoteCount: state.players.size, logStreamId: state.uniqueId, });
                        LoggingService.streamLog(state.uniqueId, `Host Reconnected to Chameleon, Reconnection Token: ${room.reconnectionToken}`);

                        if (state.chameleonData.gameState == GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, maxVoteCount: state.players.size });
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });

                    room.state.chameleonData.listen("gameState", (currentValue, previousValue) => {
                        if (currentValue != GameStates.Loading && currentValue != GameStates.EndGame && this.state.showStartWarning) {
                            this.setState({ showStartWarning: false });
                        }
                        this.setState({ gameState: currentValue });
                    });

                    room.state.players.onAdd((player, key) => {
                        console.log(player, "has been added at", key);
                        this.checkAndAddPlayer(player);
                        
                        player.onChange(() => {
                           
                        });
                        player.chameleonData.onChange(() => {
                            
                        });
                        player.listen("connected", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.connected = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        player.listen("votedSkip", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.votedSkip = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        const changes = ["score", "clueAnswer", "ready", "votedTopicId", "isChameleon", "votePlayerId", "spectating", "wordGuess"]
                        changes.forEach((change) => {
                            player.chameleonData.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };

                                statePlayer.chameleonData[change] = value;
                                
                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });
                       
                    });
                    room.state.players.onRemove((player, key) => {
                        console.log(player, "has been removed at", key);
                        this.removePlayer(player.id);
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
                        this.state.room.send("show_tutorial", {});
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, })
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.chameleonData.gameState);
                        if ((this.state.roomState.chameleonData.gameState == GameStates.Loading || this.state.roomState.chameleonData.gameState == GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
                            room.send("start_round", {});
                        }
                    });

                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        this.setRoundNumber(message.roundNumber);
                        setTimeout(() => {
                            this.playAudio(audio.NewRound);
                            this.setState({ showFolder: true, question: message.topic, showPlayers: false, });

                            setTimeout(() => {
                                this.playAudio(audio.PaperSlide1);
                                this.setState({ dropCover: true, });

                                setTimeout(() => {
                                    this.doPostItNote("Look at your devices!", () => {
                                        this.state.room.send("start_answering", {});
                                    });
                                }, 1500);
                            }, 3500);
                        }, 2000);
                    }); 

                    room.onMessage("all_clues_submitted", (message) => {
                        console.log("all_clues_submitted", "received on", room.name, message);

                        this.setState({ suspects: this.getArrayOfPlayers(message.players), });
                        this.hideTimer();

                        this.playAudio(audio.PlayerAction);
                        setTimeout(() => {
                            this.playAudio(audio.PaperSlide2);
                            this.setState({ dropQuestion: true, });

                            setTimeout(() => {

                                let index = 0;
                                const clueInterval = setInterval(() => {
                                    let suspects = [...this.state.suspects];
                                    suspects[index].showClue = true;

                                    this.setState({ suspects, });
                                    index++;

                                    if (index >= suspects.length) {
                                        setTimeout(() => {
                                            this.doPostItNote("Discuss who you think the chameleon is...", () => {
                                                this.state.room.send("start_discussion", {});
                                            });
                                        }, 2000);
                                        clearInterval(clueInterval);
                                    }
                                }, 800);
                            }, 3000);
                        }, 2000);
                    }); 

                    room.onMessage("end_discussion", (message) => {
                        console.log("end_discussion", "received on", room.name, message);
                        this.doPostItNote("Time to Vote!");
                        this.state.room.send("begin_player_vote", {});
                    });

                    room.onMessage("player_voted", (message) => {
                        console.log("player_voted", "received on", room.name, message);
                        this.playAudio(audio.PlayerAction);

                        let suspects = [...this.state.suspects];
                        suspects.forEach((x) => {
                            if (x.id == message.id) {
                                x.showVoted = true;
                            }
                        });
                        this.setState({ suspects });
                    });

                    room.onMessage("reveal_player_votes", (message) => {
                        console.log("reveal_player_votes", "received on", room.name, message);


                        this.hideTimer();
                        setTimeout(() => {
                            let suspects = [...this.state.suspects];
                            suspects.forEach(x => {
                                x.showClue = false;
                                x.showVoted = false;
                            });
                            this.setState({ suspects, });

                            this.doPostItNote("Let's see the votes!", () => {
                                setTimeout(() => {
                                    let index = 0;
                                    const votesInterval = setInterval(() => {
                                        let suspects = [...this.state.suspects];
                                        suspects[index].showVotes = true;

                                        this.setState({ suspects, });
                                        index++;

                                        if (index >= suspects.length) {
                                            setTimeout(() => {
                                                this.state.room.send("finished_revealing_votes", {});
                                            }, 4000);
                                            clearInterval(votesInterval);
                                        }
                                    }, 1000);
                                }, 500);
                            });
                        }, 1000);
                    }); 

                    room.onMessage("player_vote_decided", (message) => {
                        console.log("player_vote_decided", "received on", room.name, message);

                        this.setState({ guessChameleon: message.votedPlayer, revealChameleon: message.chameleon, })

                        if (message.voteCorrect) {
                            this.setState({ guessSubText: "Was the Chameleon!", dropSuspects: true, });
                            this.playAudio(audio.PaperSlide1);
                            setTimeout(() => {
                                this.playAudio(audio.ChameleonCaught);
                            }, 500);
                            setTimeout(() => {
                                this.playAudio(audio.PaperSlide3);
                                this.setState({ dropGuessSheet: true });
                                setTimeout(() => {
                                    this.playAudio(audio.PaperSlide2);
                                    this.setState({ dropRevealSheet: true, });
                                    setTimeout(() => {
                                        this.doPostItNote(`${message.chameleon.name}, what was the secret word?`, () => {
                                            this.state.room.send("start_word_guess", {});
                                        });
                                    }, 2000);
                                }, 100);
                            }, 5000);

                        } else {
                            this.setState({ guessSubText: "Was not the Chameleon!", revealSubText: "Was the real Chameleon!", dropSuspects: true, });
                            this.playAudio(audio.PaperSlide1);
                            setTimeout(() => {
                                this.playAudio(audio.PaperSlide3);
                                this.setState({ dropGuessSheet: true });
                                setTimeout(() => {
                                    this.playAudio(audio.ChameleonGotAway);
                                }, 500);
                                setTimeout(() => {
                                    this.playAudio(audio.PaperSlide2);
                                    this.setState({ showFolder: false, dropRevealSheet: true, });
                                    setTimeout(() => {
                                        this.playAudio(audio.PaperSlide1);
                                        this.setState({ dropWordGuessSheet: true, });
                                    }, 100);
                                    setTimeout(() => {
                                        this.state.room.send("end_round", {});
                                    }, 2000);
                                }, 5000);
                            }, 5000);
                        }
                    });

                    room.onMessage("player_vote_split", (message) => {
                        console.log("player_vote_split", "received on", room.name, message);

                        this.setState({ revealChameleon: message.chameleon, revealSubText: "Was the Chameleon!", });
                        this.doPostItNote("The Vote is Split, the Chameleon has avoided capture!", () => {
                            this.playAudio(audio.PaperSlide3);
                            this.setState({ dropSuspects: true, });
                            setTimeout(() => {
                                this.playAudio(audio.PaperSlide2);
                                this.setState({ dropGuessSheet: true, });
                                setTimeout(() => {
                                    this.playAudio(audio.ChameleonGotAway);
                                }, 500);
                                setTimeout(() => {
                                    this.playAudio(audio.PaperSlide1);
                                    this.setState({ showFolder: false, dropRevealSheet: true, });
                                    setTimeout(() => {
                                        this.playAudio(audio.PaperSlide3);
                                        this.setState({ dropWordGuessSheet: true, });
                                    }, 100);
                                    setTimeout(() => {
                                        this.state.room.send("end_round", {});
                                    }, 2000);
                                }, 5000);
                            }, 100);
                        });
                    });

                    room.onMessage("finished_word_guess", (message) => {
                        console.log("finished_word_guess", "received on", room.name, message);

                        this.setState({ guessedWord: message.wordGuess, secretWord: message.secretWord, guessCorrect: message.wordCorrect, });

                        this.hideTimer();
                        setTimeout(() => {
                            this.playAudio(audio.PaperSlide1);
                            this.setState({ showGuessedWord: true, });

                            setTimeout(() => {
                                this.playAudio(audio.PaperSlide2);
                                this.setState({ showStamp: true, });
                                if (!message.wordCorrect) {
                                    setTimeout(() => {
                                        this.playAudio(audio.PaperSlide1);
                                        this.setState({ showCorrectWord: true, });
                                        this.finishWordGuess();
                                    }, 2000);
                                } else {
                                    this.finishWordGuess();
                                }
                            }, 2000);
                        }, 1000);
                    }); 

                    room.onMessage("award_scores", (message) => {
                        console.log("award_scores", "received on", room.name, message);

                        this.resetRoundVals();
                        this.setState({ showPlayers: true, });
                        setTimeout(() => {
                            this.state.room.send("start_round", {});
                        }, 3000);
                    });

                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.setState({ winners: message.winners, showPlayers: false, });
                        this.hideTimer();
                        this.playAudio(audio.DrumRoll);
                        setTimeout(() => {
                            this.playAudio(audio.GameEnd);
                            this.setState({ showWinnerSection: true, doConfetti: true, });

                            setTimeout(() => {
                                this.state.room.send("reached_end", {});
                                this.setState({ showPlayAgainButtons: true, });
                            }, 3000);
                        }, 2000);
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.setState({ showPlayAgainButtons: false, showWinnerSection: false, });

                        this.state.room.send("start_round", {});
                    });

                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);

                        this.setState({ showTimer: true, });
                        if (message.count <= 5) {
                            if (message.count == 5) {
                                //this.playAudio(audio.Timer5SecondsStart);
                                this.playAudio(audio.Timer5SecondsClock);
                            }
                            if (message.count <= 0) {
                                this.hideTimer();
                            }
                            this.setState({ timerOptions: timerEnd });
                        } else {
                            this.setState({ timerOptions: timerTurning });
                        }
                        this.setState({ timerText: message.count, });
                    });

                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host OnError at Chameleon, code: ${code} Message: ${JSON.stringify(message)}`);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host Left Chameleon, Code: ${code}`);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Chameleon.";
                    if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Host OnJoinError at Chameleon: ${JSON.stringify(e)}`);
                    //LoggingService.logError(message, e);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}` })
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={styles.gameContainer}>
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} />
                            </div>
                            :
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showTutorial &&
                                    <Tutorial room={this.state.room} players={this.state.players} />
                                }
                                {
                                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) &&
                                    <ErrorModal
                                        title={"Are you ready to play?"}
                                        styles={"d-flex"}
                                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                                        callback={this.closeStartWarning}
                                        callbackText={"No"}
                                        callback2={this.state.showStartButtons ? this.confirmStartGame : this.confirmNewGame}
                                        callbackText2={"Yes"}
                                    />
                                }
                                {/*<div style={{ zIndex: 20, }}>*/}
                                {/*    {*/}
                                {/*        this.state.doConfetti &&*/}
                                {/*        <Confetti*/}
                                {/*            width={window.innerWidth}*/}
                                {/*            height={window.innerHeight}*/}
                                {/*            initialVelocityY={20}*/}
                                {/*            numberOfPieces={500}*/}
                                {/*            recycle={false}*/}
                                {/*            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                                {/*            initialVelocityY={{ min: -10, max: -30, }}*/}
                                {/*            initialVelocityX={{ min: -10, max: 10, }}*/}
                                {/*            onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <div className={styles.startButtonSection}>
                                        <button className={`${styles.mainButton}`} onClick={() => this.signalStartGame()}>Start Game</button>
                                        <button className={`${styles.mainButton} ${styles.smaller}`} onClick={this.goToLobby}>Go To Lobby</button>
                                        <div className={styles.skipBox}>
                                            <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                            <label for="checkbox">Skip Tutorial</label>
                                        </div>
                                    </div>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 == 0) {
                                                return <Player player={x} left={true} />
                                            }
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 == 1) {
                                                return <Player player={x} />
                                            }
                                        })
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.playAgainButtonSection}>
                                        <button className={`${styles.button}`} onClick={() => this.signalNewGame()}>
                                            <img className={styles.buttonImg} src={ButtonImg1} />
                                            Play Again
                                        </button>
                                        <button className={`${styles.button}`} onClick={this.goToLobby}>
                                            <img className={`${styles.buttonImg}`} src={ButtonImg2} />
                                            Return to lobby
                                        </button>
                                    </div>
                                }
                                <div className={`${styles.timerSection} ${this.state.showTimer && styles.show}`}>
                                    <div className={styles.timerText}>{this.state.timerText}</div>
                                    <div className={styles.clock}>
                                        <Lottie
                                            options={this.state.timerOptions}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true} />
                                    </div>
                                </div>
                                <div className={`${styles.roundFolder} ${this.state.showFolder && styles.show} ${this.state.dropCover && styles.zoom}`}>
                                    <div id="Back" className={`${styles.folderSegment} ${this.state.dropCover && styles.drop}`}>
                                        <img src={folderBack} className={`${styles.sheet}`} />
                                    </div>
                                    <div id="WordGuessSheet" className={`${styles.folderSegment} ${styles.rotate1} ${this.state.dropWordGuessSheet && styles.drop}`}>
                                        <img src={folderCardWhite} className={`${styles.sheet}`} />
                                        <div className={`${styles.cardContent} ${styles.white}`}>
                                            <div className={styles.questionCard}>
                                                <div className={styles.questionTitle}>{this.state.question.Topic}</div>
                                                <div className={styles.questionTable}>
                                                    {
                                                        this.state.question.Answers?.map((x, index) => {
                                                            return <div className={styles.tableItem}>
                                                                <div className={styles.itemText}>{x}</div>
                                                                {
                                                                    x == this.state.guessedWord && this.state.showGuessedWord &&
                                                                    <img src={wordCircle} className={styles.wordCircle} />
                                                                }
                                                                {
                                                                    x == this.state.guessedWord && this.state.showStamp &&
                                                                    <img src={this.state.guessCorrect ? correctStamp : wrongStamp} className={styles.wordStamp} />
                                                                }
                                                                {
                                                                    x == this.state.secretWord && this.state.showCorrectWord &&
                                                                    <img src={wordCircle} className={styles.wordStamp} />
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="RevealSheet" className={`${styles.folderSegment} ${styles.rotate2} ${this.state.dropRevealSheet && styles.drop}`}>
                                        <img src={folderCardGreen} className={`${styles.sheet}`} />
                                        <div className={`${styles.cardContent} ${styles.green}`}>
                                            <div className={styles.guessCard}>
                                                <div className={`${styles.potato}`}>
                                                    {
                                                        this.state.revealChameleon.avatar != null &&
                                                        <Lottie
                                                            options={getAvatarById(this.state.revealChameleon.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    }
                                                </div>
                                                <div className={styles.name}>{this.state.revealChameleon.name}</div>
                                                <div className={styles.subText}>{this.state.revealSubText}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="GuessSheet" className={`${styles.folderSegment} ${styles.rotate3} ${this.state.dropGuessSheet && styles.drop}`}>
                                        <img src={folderCardGreen} className={`${styles.sheet}`} />
                                        <div className={`${styles.cardContent} ${styles.green}`}>
                                            <div className={styles.guessCard}>
                                                <div className={`${styles.potato}`}>
                                                    {
                                                        this.state.guessChameleon.avatar != null &&
                                                        <Lottie
                                                            options={getAvatarById(this.state.guessChameleon.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    }
                                                </div>
                                                <div className={styles.name}>{this.state.guessChameleon.name}</div>
                                                <div className={styles.subText}>{this.state.guessSubText}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Suspects" className={`${styles.folderSegment} ${styles.rotate4} ${this.state.dropSuspects && styles.drop}`}>
                                        <img src={folderCardWhite} className={`${styles.sheet}`} />
                                        <div className={`${styles.cardContent} ${styles.white}`}>
                                            <div className={styles.suspectCard}>
                                                <div className={styles.suspectTitle}>SUSPECT SHEET</div>
                                                <div className={styles.suspectSection}>
                                                    {
                                                        this.state.suspects.map((x, index) => {
                                                            if (!x.chameleonData.spectating) {
                                                                return <div className={styles.suspect}>
                                                                    <img src={suspectBox} className={styles.suspectBg} />
                                                                    <img src={getAvatarById(x.avatar).mugShot} className={styles.mugshot} />
                                                                    <img src={votedStamp} className={`${styles.votedStamp} ${x.showVoted && styles.show}`} />
                                                                    <div className={styles.suspectContent}>
                                                                        <div className={styles.nameBox}>
                                                                            <div className={styles.name}>Suspect name:<span className={styles.written}> {x.name}</span></div>
                                                                        </div>
                                                                        <div className={styles.largeBox}>
                                                                            {
                                                                                x.showClue &&
                                                                                <React.Fragment>
                                                                                    <div className={styles.smallTitle}>Clue:</div>
                                                                                    <div className={styles.clue}>{x.chameleonData.clueAnswer}</div>
                                                                                </React.Fragment>
                                                                            }
                                                                            {
                                                                                x.showVotes &&
                                                                                <React.Fragment>
                                                                                    <div className={styles.smallTitle}>Votes:</div>
                                                                                    <div className={styles.voteList}>{this.getVoteCount(x.id)}</div>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Question" className={`${styles.folderSegment} ${styles.rotate5} ${this.state.dropQuestion && styles.drop}`}>
                                        <img src={folderCardWhite} className={`${styles.sheet}`} />
                                        <div className={`${styles.cardContent} ${styles.white}`}>
                                            <div className={styles.questionCard}>
                                                <div className={styles.questionTitle}>{this.state.question.Topic}</div>
                                                <div className={styles.questionTable}>
                                                    {
                                                        this.state.question.Answers?.map((x, index) => {
                                                            return <div className={styles.tableItem}>
                                                                <div className={styles.itemText}>{x}</div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.postItNote} ${this.state.showPostItNote && styles.show}`}>
                                        <img src={postItNote} className={styles.postItImg} />
                                        <div className={styles.postItText}>{this.state.postItText}</div>
                                    </div>
                                    <div id="Front" className={`${styles.folderSegment} ${this.state.dropCover && styles.drop}`}>
                                        <img src={folderFront} className={`${styles.sheet}`} />
                                        <img src={this.state.roundNumberImage} className={`${styles.roundNumber}`} />
                                    </div>
                                </div>

                                <div className={`${styles.gameOverSection} ${this.state.showWinnerSection && styles.show}`}>
                                    <div className={`${styles.winnerHalf}`}>
                                        <div className={`${styles.winnerBoxes}`}>
                                            <div className={styles.spotlight}></div>
                                            {
                                                this.getWinnerBoxes()
                                            }
                                        </div>
                                    </div>
                                    <div className={`${styles.leaderboardSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                        {
                                            this.getSortedPlayersByScore()
                                        }
                                    </div>                                   
                                </div>
                                {/*<div style={{ position: "absolute" }}>*/}
                                {/*    <button onClick={() => this.setState({ showPlayAgainButtons: !this.state.showPlayAgainButtons })}>showPlayAgainButtons</button>*/}
                                {/*    <button onClick={() => this.setState({ showWinnerSection: !this.state.showWinnerSection })}>showWinnerSection</button>*/}
                                {/*</div>*/}
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}